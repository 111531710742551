@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* FontAwesome for working BootSnippet :> */
#team {
    background: #eee !important;
}
@font-face {
    font-family: 'Calibri';
    src: url(/static/media/CalibriRegular.5d7c31b2.ttf);
  }

.btn-primary:hover,
.btn-primary:focus {
    background-color: #108d6f;
    border-color: #108d6f;
    box-shadow: none;
    outline: none;
}

.btn-primary {
    color: #fff;
    background-color: #007b5e;
    border-color: #007b5e;
}

section {
    padding: 60px 0;
}

section .section-title {
    text-align: center;
    color: #007b5e;
    margin-bottom: 50px;
    text-transform: uppercase;
}

#team .card {
    border: none;
    background: #ffffff;
}

.image-flip:hover .backside,
.image-flip.hover .backside {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    border-radius: .25rem;
}

.image-flip:hover .frontside,
.image-flip.hover .frontside {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
}


.mainflip {
    -webkit-transition: 1s;
    -webkit-transform-style: preserve-3d;
    -ms-transition: 1s;
    -moz-transition: 1s;
    -moz-transform: perspective(1000px);
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transition: 1s;
    transform-style: preserve-3d;
    position: relative;
}

.frontside {
    position: relative;
    -webkit-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    z-index: 2;
    margin-bottom: 30px;
}

.backside {
    position: absolute;
    top: 0;
    left: 0;
    background: white;
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
}

.frontside,
.backside {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: 1s;
    -webkit-transform-style: preserve-3d;
    -moz-transition: 1s;
    -moz-transform-style: preserve-3d;
    -o-transition: 1s;
    -o-transform-style: preserve-3d;
    -ms-transition: 1s;
    -ms-transform-style: preserve-3d;
    transition: 1s;
    transform-style: preserve-3d;
}

.frontside .card,
.backside .card {
    min-height: 312px;
}

.backside .card a {
    font-size: 18px;
    color: #007b5e !important;
}

.frontside .card .card-title,
.backside .card .card-title {
    color: #007b5e !important;
}

.frontside .card .card-body img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
}

.shadowForProjects{
    box-shadow: 0 0px 10px -3px !important;
    border-radius: 8px;
    /* background-color: papayawhip; */
    height:94%;
    width:109%
  }

  .shadowForunderprepartion{
    /* box-shadow: 0 0px 10px -3px !important; */
    border-radius: 5px;
    /* background-color: papayawhip; */
    height:90%;
    width:95%
  }
.cardbodycss{
    padding:0.75rem
}

.backgroundimagecss{
    /* background-image: url("image.jpg");  */
    /* background-repeat: no-repeat */
    background-size: cover;
    /* height: 100%  ; */
}

/* style={{minHeight:'1080px',minWidth:'1366px',maxHeight:'1080px',maxWidth:'1920px',overflowX:'hidden',overflowY:'hidden',backgroundImage: a ,backgroundSize: 'cover'}} */


/* Extra small devices (portrait phones, less than 576px) */
/* @media (max-width: 575.98px) { ... } */

/* Small devices (landscape phones, 576px and up) */
/* @media (min-width: 576px) and (max-width: 767.98px) { ... } */

/* Medium devices (tablets, 768px and up) */
@media (min-width: 1290px) and (max-width: 1291px) { 
  .content {
      height: 766px;
      width: 1366px;
      background-color: yellow;
  }
 }

/* Large devices (desktops, 992px and up) */
@media (min-width: 1920px) and (max-width: 2000px)
 { 
  .content {
    height: 1080px;
    width: 1920px;
  }
 }
 @media (min-width: 3676.8px) and (max-width: 3677px) 
 { 
    .content {
      height: 20000px;
      width: 3676.8px;
      background-color: blue;
    }
   }
   @media (min-width: 1875px) and (max-width: 1876px) 
   { 
    .content {
      height: 1368px;
      width: 1875px;
      background-color: green;
    }
   }

/* Extra large devices (large desktops, 1200px and up) */
/* @media (min-width: 1200px) { ... } */ 
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

